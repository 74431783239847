var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-12" },
    [
      _c(
        "b-button",
        {
          staticClass: "p-0",
          class: _vm.position == "left" ? "float-left" : "float-right",
          attrs: { variant: "link" },
          on: {
            click: function ($event) {
              return _vm.$emit("onclick")
            },
          },
        },
        [
          _c("fa-icon", {
            staticClass: "fa-s",
            attrs: { icon: "chevron-left" },
          }),
          _vm._v("\n    Back\n  "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }