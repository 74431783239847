<template>
  <span class="float-right">
    <b-button
      variant="link"
      class="p-0"
      @click="$emit('onclick')"
    >
      {{ text }}
    </b-button>
    <feather-icon type="alert-circle" width="15" height="15" class="pl-2" style="color:red" />
  </span>
</template>

<script>
import FeatherIcon from '@/components/shared/FeatherIcon'

export default {
  name: 'AddButton',
  components: {
    FeatherIcon,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>
