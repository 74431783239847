<template>
  <b-container id="select-payment" fluid>
    <div style="padding-inline: 1rem">
      <ach-limit-label />
    </div>
    <b-card-header header-tag="header" role="tab">
      <div class="row change-to-flex-column ">
        <div class="change-to-flex-column" :class="missingPaymentMethod || isBillingActive ? 'col-sm-4' : 'col-sm-3'">
          <h5 class="card-header-text">
            {{ paymentHeaderText }}
          </h5>
        </div>
        <div v-if="!missingPaymentMethod" :class="`${$mq !== 'sm' ? 'col-7' : 'col-10'} ${ isBillingActive ? 'panel_active' : ''}`">
          <selected-payment-header />
        </div>

        <div v-if="showPaymentForm" :class="$mq !== 'sm' ? 'col-8' : 'col-12'">
          <back-button position="right" @onclick="backToOptions()" />
        </div>
        <div
          v-else-if="missingPaymentMethod"
          class="change-to-flex-column"
          :class="$mq !== 'sm' ? 'col-8' : 'col-12'"
        >
          <add-button
            text="Add Payment Method"
            :class="{ panel_active: isBillingActive }"
            @onclick="showAddForm()"
          />
        </div>
        <div v-else class="col-2">
          <change-button
            :class="{ panel_active: isBillingActive }"
            @onclick="togglePanel()"
          />
        </div>
      </div>
    </b-card-header>

    <b-collapse id="accordion-billing" accordion="request-document" role="tabpanel">
      <b-card-body>
        <div v-if="!showPaymentForm">
          <div class="container pl-4 pr-4">
            <b-form-group class="pt-3 pr-4 pl-4 pb-0 mb-0">
              <b-form-radio
                v-for="card in cards"
                :key="card.id"
                v-model="paymentMethodRadioValue"
                name="card-radio"
                :value="card"
                class="mb-1"
                :disabled="card.expired"
                @change="selectedPaymentMethodChanged(paymentMethodRadioValue, 'card')"
              >
                <div class="row radio-margin-bottom">
                  <div class="col-10">
                    <b-img :src="cardLogo(card.brand)" class="cc-logo ml-3 mr-2" />
                    {{ `**** **** **** ${card.last4}` }}
                    <span v-cc-expiration="{month: card.exp_month, year: card.exp_year}" class="ml-4" />
                    <expired-card-label class="ml-2" :card="card" />
                  </div>
                  <div class="col-2 pr-0">
                    <a class="edit-link float-right" @click="editPaymentMethod(card, 'card')">
                      Edit
                    </a>
                  </div>
                </div>
              </b-form-radio>

              <b-form-radio
                v-for="ach in automatedClearingHouses"
                :key="ach.id"
                v-model="paymentMethodRadioValue"
                name="card-radio"
                :value="ach"
                class="mb-1"
                :disabled="exceedsAchTransactionLimit"
                @change="selectedPaymentMethodChanged(paymentMethodRadioValue, 'automatedClearingHouse')"
              >
                <div class="row radio-margin-bottom">
                  <div class="col-10">
                    <b-img src="/images/automatedClearingHouses/ach.svg" class="ml-3 mr-2" />
                    {{ maskAccountNumber(ach.bank_account_number) }}
                  </div>
                  <div class="col-2 pr-0">
                    <a class="edit-link float-right" @click="editPaymentMethod(ach, 'automatedClearingHouse')">
                      Edit
                    </a>
                  </div>
                </div>
              </b-form-radio>

              <b-form-radio
                :key="`add-payment-method`"
                v-model="paymentMethodRadioValue"
                name="card-radio"
                :value="{ id: 'ADD'}"
                class="mb-1"
                @change="selectedPaymentMethodChanged(paymentMethodRadioValue, 'card')"
              >
                <div class="row radio-margin-bottom col-10">
                  <b-img :src="cardLogo(null)" class="cc-logo ml-3 mr-2" />
                  Add a Payment Method
                </div>
              </b-form-radio>
            </b-form-group>
            <div class="text-center">
              <b-button
                class="continue-payment-button mt-2 mb-4"
                variant="primary"
                :class="$mq !== 'sm' ? 'col-sm-6' : 'col-sm-12'"
                @click="closeSection()"
              >
                Continue
              </b-button>
            </div>
          </div>
        </div>
        <payment-method-form
          v-else-if="showPaymentForm"
          submit-button-text="Continue"
          :payment-form-description="missingPaymentMethod ? addPaymentDescription : editPaymentDescription"
          @update="updatePaymentMethod"
          @badCardIssuer="setBadCardIssuer"
        />
      </b-card-body>
    </b-collapse>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { AddButton, BackButton, ChangeButton, SelectedPaymentHeader } from '@/components/shared/PaymentModals/index'
import { getCardLogo } from '@/common/modules/cc'
import TokenizerClient from '@/common/modules/tokenizer-client'
import { ccExpiration } from '@/directives/directives'
import ExpiredCardLabel from '@/components/Payments/ExpiredCards/ExpiredCardLabel'
import PaymentMethodForm from '@/components/PaymentMethodForm'
import { paymentMethodsMixin } from '@/mixins/paymentMethodsMixin'
import AchLimitLabel from '@/components/Payments/AchLimitLabel'

export default {
  name: 'SelectPaymentMethod',
  components: {
    AchLimitLabel,
    AddButton,
    BackButton,
    ChangeButton,
    ExpiredCardLabel,
    PaymentMethodForm,
    SelectedPaymentHeader,
  },
  directives: {
    ccExpiration,
  },
  mixins: [paymentMethodsMixin],
  props: {
    isBillingActive: {
      type: Boolean,
      default: false,
    },
    setDefaultValues: {
      type: Boolean,
      default: true,
    },
    paymentHeaderText: {
      type: String,
      default: 'Payment',
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedBillingAddress: null,
      showPaymentForm: false,
      editSelectedPaymentMethod: false,
      paymentMethodRadioValue: null,
      editPaymentDescription: 'This Payment Method will be updated',
      addPaymentDescription: 'This Payment Method will be added',
      badCardIssuer: false,
      tokenizerClient: new TokenizerClient(this.$store),
    }
  },
  computed: {
    ...mapGetters('session', [
      'getToken',
    ]),
    missingPaymentMethod() {
      return (this.selectedPaymentMethod == null || this.selectedPaymentMethod.id === 'ADD')
    },
    hasPaymentOptions() {
      return this.cards.length > 0 || this.automatedClearingHouses.length > 0
    },
  },
  async mounted() {
    await this.retryPaymentMethods()
    this.setCurrentTotal(this.total)
    this.setDefaultValues ? this.loadDefaultPaymentMethod()
                          : this.paymentMethodRadioValue = this.selectedPaymentMethod
  },
  methods: {
    clearSelection() {
      this.paymentMethodRadioValue = null
      this.resetPaymentMethod()
    },
    setDefaultFallbackPaymentMethod() {
      if (!this.selectedPaymentMethod) {
        this.loadDefaultPaymentMethod()
      }
    },
    loadDefaultPaymentMethod() {
      if (this.cards.filter(card => !card.expired).length > 0) {
        if (this.selectedPaymentMethod === null) {
          this.setPaymentMethod({ payable: this.cards.filter(card => !card.expired)[0], type: 'card' })
        } else {
          this.setPaymentMethod({payable: this.selectedPaymentMethod, type: this.selectedPayableType})
        }
      } else if (this.automatedClearingHouses.length > 0) {
        this.setPaymentMethod({ payable: this.automatedClearingHouses[0], type: 'automatedClearingHouse' })
      }
    },
    async editPaymentMethod(payable, payableType) {
      this.setPaymentMethodSelection(payable, payableType)
      this.showPaymentForm = true
      this.editSelectedPaymentMethod = true

      if (this.selectedPaymentMethod.billing_address) {
        this.selectedBillingAddress = this.tokenizerClient.addressFromTokenizer(this.selectedPaymentMethod.billing_address)
      }
    },
    async updatePaymentMethod(payableId, errors) {
      await this.closeSection()
      this.displayResultToast(payableId, this.editSelectedPaymentMethod, errors)
    },
    cardLogo(brand) {
      return getCardLogo(brand)
    },
    backToOptions() {
      this.setPaymentMethodSelection(null, 'card')
      this.editSelectedPaymentMethod = false
      this.showPaymentForm = false
    },
    showAddForm() {
      this.togglePanel()
      this.showPaymentForm = true
    },
    setPaymentMethodSelection(paymentMethod, type) {
      this.paymentMethodRadioValue = paymentMethod
      this.setPaymentMethod({ payable: paymentMethod, type: type })
    },
    selectedPaymentMethodChanged(paymentMethod, paymentMethodType) {
      if (paymentMethod.id === 'ADD') {
        this.setPaymentMethodSelection(null, 'card')
        this.showPaymentForm = true
      } else if (this.editSelectedPaymentMethod) {
        this.showPaymentForm = true
      } else {
        this.setPaymentMethodSelection(paymentMethod, paymentMethodType)
      }
    },
    maskAccountNumber(accountNumber) {
      return accountNumber.replace(/X/g, '*')
    },
    closeSection() {
      this.setDefaultFallbackPaymentMethod()
      this.editSelectedPaymentMethod = false
      this.showPaymentForm = false
      this.$emit('closeSection')
      this.$root.$emit('bv::toggle::collapse', 'accordion-billing')
    },
    togglePanel() {
      this.clearSelection()
      this.editSelectedPaymentMethod = false
      this.$emit('togglePanel')
      this.$root.$emit('bv::toggle::collapse', 'accordion-billing')
    },
    setBadCardIssuer() {
      this.badCardIssuer = true
    },
  },
}
</script>

<style lang="scss">

#select-payment {
  margin-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .card-body {
    padding: 0px !important;
  }

  .card-header-text {
    display: inline-block;
    float: left;
    white-space: nowrap;
  }

  .edit-link {
    color: #007bff !important;
    cursor: pointer;
    padding-right: 1.5625rem;
  }

  .edit-link:hover {
    text-decoration: underline !important;
  }

  .panel_active {
    display: none;
  }

  .radio-margin-bottom {
    margin-bottom: .25rem !important;
  }

  .custom-control-label {
    width: 100% !important;
  }

  .card-header {
    background: white !important;
    padding: .4rem 1.2rem 0 1.2rem !important;
    border-bottom: none;
  }

  .continue-payment-button {
    border-radius: 4px !important;
    padding: 8px 16px 8px 10px !important;
    max-width: 320px;
  }
}

@media only screen and (max-width: 740px) {
  .change-to-flex-column {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}
</style>
