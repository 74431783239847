var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "float-right" },
    [
      _c(
        "b-button",
        {
          staticClass: "p-0",
          attrs: { variant: "link" },
          on: {
            click: function ($event) {
              return _vm.$emit("onclick")
            },
          },
        },
        [_vm._v("\n    Change\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }