import { render, staticRenderFns } from "./SelectedPaymentHeader.vue?vue&type=template&id=3706178a"
import script from "./SelectedPaymentHeader.vue?vue&type=script&lang=js"
export * from "./SelectedPaymentHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3706178a')) {
      api.createRecord('3706178a', component.options)
    } else {
      api.reload('3706178a', component.options)
    }
    module.hot.accept("./SelectedPaymentHeader.vue?vue&type=template&id=3706178a", function () {
      api.rerender('3706178a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared/PaymentModals/SelectedPaymentHeader.vue"
export default component.exports