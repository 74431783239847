<template>
  <div class="col-12">
    <b-button
      variant="link"
      class="p-0"
      :class="position == 'left' ? 'float-left' : 'float-right'"
      @click="$emit('onclick')"
    >
      <fa-icon icon="chevron-left" class="fa-s" />
      Back
    </b-button>
  </div>
</template>

<script>

export default {
  name: 'BackButton',
  props: {
    position: {
      type: String,
      default: 'left',
    },
  },
}
</script>
