<template>
  <div style="display:inline-flex">
    <div v-if="selectedPayableType === 'card'" class="d-flex align-items-center">
      <b-img :src="cardLogo" class="cc-logo mr-2" />
      {{ `**** **** **** ${selectedPaymentMethod.last4}` }}
      <span class="ml-4" v-cc-expiration="{month: selectedPaymentMethod.exp_month, year: selectedPaymentMethod.exp_year}" />
      <expired-card-label class="ml-2" :card="selectedPaymentMethod" />
    </div>
    <div v-else-if="selectedPayableType=== 'automatedClearingHouse'">
      <b-img :src="`/images/automatedClearingHouses/ach.svg`" class="mr-2" />
      {{ maskAccountNumber(selectedPaymentMethod.bank_account_number) }}
    </div>
  </div>
</template>

<script>
import { getCardLogo } from '@/common/modules/cc'
import { mapGetters } from 'vuex'
import { ccExpiration } from '@/directives/directives'
import ExpiredCardLabel from '@/components/Payments/ExpiredCards/ExpiredCardLabel'

export default {
  name: 'SelectedShippingHeader',
  components: {
    ExpiredCardLabel,
  },
  directives: {
    ccExpiration,
  },
  computed: {
    ...mapGetters('paymentMethods', ['selectedPaymentMethod', 'selectedPayableType']),
    cardLogo() {
      return getCardLogo(this.selectedPaymentMethod.brand)
    },
  },
  methods: {
    maskAccountNumber(accountNumber) {
      return accountNumber.replace(/X/g, '*')
    },
  },
}
</script>
