<template>
  <span class="float-right">
    <b-button
      variant="link"
      class="p-0"
      @click="$emit('onclick')"
    >
      Change
    </b-button>
  </span>
</template>

<script>

export default {
  name: 'ChangeButton',
}
</script>
